<template>
  <!-- main @s -->
  <div class="nk-main ">
    <header class="header has-header-main-s1 bg-dark next-overlap-3x" id="home">
      <div class="header-main header-main-s1 is-sticky is-transparent on-dark">
        <div class="container header-container">
          <div class="header-wrap">
            <div class="header-logo">
              <router-link to="/" class="logo-link">
                <img class="logo-light logo-img" src="@/assets/img/logo-white.png" alt="logo">
                <img class="logo-dark logo-img" src="@/assets/img/logo-green.png" alt="logo-dark">
              </router-link>
            </div>
            <div class="header-toggle">
              <button class="menu-toggler" data-target="mainNav">
                <em class="menu-on icon ni ni-menu"></em>
                <em class="menu-off icon ni ni-cross"></em>
              </button>
            </div><!-- .header-nav-toggle -->
            <nav-header/>
          </div><!-- .header-warp-->
        </div><!-- .container-->
      </div><!-- .header-main-->
      <home-content/>
    </header><!-- .header -->
    <services/>
    <products/>
    <stats/>
    <infrastructures/>
    <suggestion/>
    <download/>
    <clients/>
    <Footer/>
  </div>
  <!-- main @e -->
</template>

<script>
import NavHeader from "../components/NavHeader";
import HomeContent from "../components/HomeContent";
import Suggestion from "../components/suggestion";
import Download from "../components/download";
import Clients from "../components/clients";
import Infrastructures from "../components/infrastructures";
import Stats from "../components/stats";
import Products from "../components/products";
import Services from "../components/services";
import Footer from "../components/Footer";
//import Privacy from "../components/privacy";

export default {
  name: "Landing",
  components: {Footer, Services, Products, Stats, Infrastructures, Clients, Download, Suggestion, HomeContent, NavHeader}
}
</script>

<style scoped>

</style>