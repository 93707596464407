<template>
  <section class="section section-service " id="service">
    <div class="container">
      <div class="section-content">
        <div class="row justify-content-start text-left g-gs">
          <div class="col-md-6 col-lg-4">
            <div class="card card-shadow">
              <div class="card-inner card-inner-lg">
                <div class="service">
                  <div class="service-icon styled-icon styled-icon-6x text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" style="fill: currentColor">
                      <path d="M64.77,26.89,34.1,48,4.5,26.89,32.84,7.4a2.26,2.26,0,0,1,2.53,0Z" fill="#fff"></path>
                      <path
                          d="M4.42,26H64.56A1.45,1.45,0,0,1,66,27.44V60.58A1.43,1.43,0,0,1,64.58,62H4.44A1.45,1.45,0,0,1,3,60.56V27.42A1.43,1.43,0,0,1,4.42,26Z"
                          fill="#fff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2"></path>
                      <path
                          d="M18.51,36.11,28.66,16.77,45.6,25.49,37,41.91,35.1,40.79a1.39,1.39,0,0,0-1.55,0l-4.34,2.82Z"
                          fill="#c4cefe" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2"></path>
                      <path d="M26.52,41.7l3.33-14s18.24,4,18.7,4.35l-1.48,6.25-7.76,5.19-5.09-3.11-5,3.23Z"
                            fill="#fff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2"></path>
                      <line x1="32.47" y1="32.04" x2="42.98" y2="34.5" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <line x1="31.64" y1="35.5" x2="42.16" y2="37.96" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <line x1="30.82" y1="38.96" x2="41.34" y2="41.41" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <line x1="30.17" y1="41.73" x2="40.68" y2="44.18" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <line x1="29.51" y1="44.5" x2="35.08" y2="45.8" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <path d="M64.64,26.43,34.1,47,4.64,26.43l28.21-19a2.29,2.29,0,0,1,2.52,0Z" fill="none"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2"></path>
                      <path d="M65.72,61.48,35.71,41A2.31,2.31,0,0,0,33,40.92L3.71,61.36" fill="#eff1ff"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2"></path>
                      <path
                          d="M66.89,82.72,45.69,70.18A2,2,0,0,1,45,67.45L62.07,39.13a2,2,0,0,1,2.75-.69L86,51a2,2,0,0,1,.7,2.72L69.64,82A2,2,0,0,1,66.89,82.72Z"
                          fill="#fff"></path>
                      <path
                          d="M66.89,82.72,45.69,70.18A2,2,0,0,1,45,67.45L62.07,39.13a2,2,0,0,1,2.75-.69L86,51a2,2,0,0,1,.7,2.72L69.64,82A2,2,0,0,1,66.89,82.72Z"
                          fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2"></path>
                      <ellipse cx="72.6" cy="48.73" rx="1.44" ry="1.42" fill="#c4cefe" stroke="currentColor"
                               stroke-miterlimit="10" stroke-width="2"></ellipse>
                      <path
                          d="M61.92,67.69l-8.41-5a1,1,0,0,1-.34-1.36l.31-.52a1,1,0,0,1,1.37-.34l8.41,5a1,1,0,0,1,.35,1.36l-.31.51A1,1,0,0,1,61.92,67.69Z"
                          fill="#9cabff"></path>
                      <line x1="77.65" y1="61.3" x2="59.79" y2="50.73" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <line x1="76.21" y1="63.69" x2="58.34" y2="53.13" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <line x1="74.76" y1="66.09" x2="56.9" y2="55.52" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <line x1="73.31" y1="68.49" x2="55.45" y2="57.92" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <polyline points="68.01 52.34 61.48 48.47 61.48 48.47" fill="none" stroke="currentColor"
                                stroke-linecap="round" stroke-linejoin="round"></polyline>
                      <line x1="67.67" y1="77.86" x2="49.8" y2="67.29" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"></line>
                      <polyline points="57.43 68.5 51.51 64.99 51.51 64.99" fill="none" stroke="currentColor"
                                stroke-linecap="round" stroke-linejoin="round"></polyline>
                    </svg>
                  </div><!-- .service-icon -->
                  <div class="service-text">
                    <h4 class="title">Communication Channels</h4>
                    <p>A Platform to send bulk informative sms, emails and whatsapp messages.
                    </p>
                  </div><!-- .service-text -->
                </div><!-- .service -->
              </div><!-- .card-inner -->
            </div><!-- .card -->
          </div><!-- .col -->
          <div class="col-md-6 col-lg-4">
            <div class="card card-shadow">
              <div class="card-inner card-inner-lg">
                <div class="service">
                  <div class="service-icon styled-icon styled-icon-6x text-primary">
                    <svg viewBox="0 0 512 512" style="fill:currentColor" xml:space="preserve">
                                                    <path style="clip-path:url(#SVGID_4_)"
                                                          d="M356.1,49.5l3.5-10.4c1.5-4.4-0.9-9.1-5.2-10.6c-4.4-1.5-9.1,0.9-10.6,5.2l-3.5,10.4			c-6.1-0.5-12.2,0.6-17.9,3.4c-8.2,4-14.3,11-17.2,19.7l-32.7,96.2h-10.1l-1.7-120.9c-0.2-11.2-9.4-20.4-20.7-20.4			c-11.2,0-20.5,9.1-20.7,20.4l-1.7,120.9h-19.1V66.1c0-1.7-0.5-3.3-1.4-4.6L158.5,3.8c-1.5-2.3-4.1-3.7-6.9-3.7			c-2.8,0-5.4,1.4-6.9,3.7L106,61.5c-0.9,1.4-1.4,3-1.4,4.6v97.3H84.3c-4.6,0-8.3,3.7-8.3,8.3s3.7,8.3,8.3,8.3H86v28.1H53.8			c-29.5,0-53.6,24-53.6,53.6v122.1c0,29.5,24,53.6,53.6,53.6H86v26.3c0,26.6,21.7,48.3,48.3,48.3h175.6c26.6,0,48.3-21.7,48.3-48.3			V180.1h1.7c4.6,0,8.3-3.7,8.3-8.3s-3.7-8.3-8.3-8.3h-15.1L370,89.2C375,74.1,368.9,57.8,356.1,49.5 M85.9,389.6H56.7			c-4.9,0-8.8-4-8.8-8.8V264.8c0-4.9,4-8.8,8.8-8.8h29.2V389.6z M85.9,239.2H56.7c-14.1,0-25.5,11.4-25.5,25.5v116.1			c0,14.1,11.4,25.5,25.5,25.5h29.2v14.4H53.7c-20.4,0-36.9-16.6-36.9-36.9V261.8c0-20.4,16.6-36.9,36.9-36.9h32.2V239.2z			 M301.9,128.6l33.1,11.3l-8,23.6h-37L301.9,128.6z M236.1,42.8c0-2.2,1.8-4,4-4s4,1.8,4,4l1.7,120.6h-11.4L236.1,42.8z			 M223.4,180.1c0.5,0.1,1,0.3,1.5,0.3v94.8c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2v-26.8c0-13.7-11.2-24.9-24.9-24.9			c-13.7,0-24.9,11.2-24.9,24.9v52c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2V180.1H223.4z M181.9,163.4h-22V82.1			c7.4-0.6,14.8-2,22-4.1V163.4z M151.6,23.4l8.3,12.4c-2.7,0.8-5.4,1.2-8.3,1.2c-2.8,0-5.6-0.4-8.3-1.2L151.6,23.4z M133.8,50			c5.6,2.4,11.6,3.7,17.8,3.7s12.2-1.3,17.8-3.7l8,12c-16.8,5.1-34.9,5.1-51.7,0L133.8,50z M121.2,78c7.2,2.1,14.6,3.5,22,4.1v81.3			h-22V78z M341.4,463.8c0,17.4-14.2,31.6-31.6,31.6H134.2c-17.4,0-31.6-14.2-31.6-31.6V180.1h22.8v120.3			c0,13.7,11.2,24.9,24.9,24.9s24.9-11.2,24.9-24.9v-52c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2v26.8c0,13.7,11.2,24.9,24.9,24.9			c13.7,0,24.9-11.2,24.9-24.9v-94.7l99.9-0.4L341.4,463.8L341.4,463.8z M354.1,83.8l-13.7,40.3l-33.1-11.3L321,72.6			c1.5-4.4,4.6-8,8.8-10.1c4.2-2.1,8.9-2.4,13.4-0.9C352.3,64.7,357.2,74.7,354.1,83.8z"></path></svg>
                  </div><!-- .service-icon -->
                  <div class="service-text">
                    <h4 class="title">City Neatness</h4>
                    <p>Keeping your residents up to date with when their dustbins will be collected.</p>
                  </div><!-- .secvice-text -->
                </div><!-- .service -->
              </div><!-- .card-inner -->
            </div><!-- .card -->
          </div><!-- .col- -->
          <div class="col-md-12 col-lg-4">
            <div class="card card-shadow">
              <div class="card-inner card-inner-lg">
                <div class="service">
                  <div class="service-icon styled-icon styled-icon-6x text-primary">
                    <svg viewBox="0 0 512 512" style="fill:currentColor" xml:space="preserve">
                                                    <path d="M240.6,177.3c-51.6,0-93.6,42-93.6,93.6s42,93.6,93.6,93.6c51.6,0,93.6-42,93.6-93.6c0-22.3-7.9-42.9-21-59l28.9-28.9
	c20.5,23.6,32.9,54.3,32.9,87.9c0,74.1-60.3,134.4-134.4,134.4c-74.1,0-134.4-60.3-134.4-134.4c0-74.1,60.3-134.4,134.4-134.4
	c33.6,0,64.4,12.4,87.9,32.9l-28.9,28.9C283.5,185.2,263,177.3,240.6,177.3z M233.8,277.7c1.9,1.9,4.3,2.8,6.8,2.8
	c2.5,0,4.9-0.9,6.8-2.8l52.1-52.1c9.7,12.6,15.5,28.3,15.5,45.3c0,41-33.4,74.4-74.4,74.4c-41,0-74.4-33.4-74.4-74.4
	c0-41,33.4-74.4,74.4-74.4c17.1,0,32.8,5.8,45.3,15.5l-52.1,52.1C230.1,267.8,230.1,273.9,233.8,277.7z M434.7,261.3h-13.5
	c-5.3,0-9.6,4.3-9.6,9.6c0,5.3,4.3,9.6,9.6,9.6h13.5c-4.9,99.5-85,179.6-184.5,184.5v-13.5c0-5.3-4.3-9.6-9.6-9.6
	c-5.3,0-9.6,4.3-9.6,9.6V465c-99.5-4.9-179.6-85-184.5-184.5H60c5.3,0,9.6-4.3,9.6-9.6c0-5.3-4.3-9.6-9.6-9.6H46.5
	c4.9-99.5,85-179.6,184.5-184.5v13.5c0,5.3,4.3,9.6,9.6,9.6c5.3,0,9.6-4.3,9.6-9.6V76.8c46.4,2.3,88.5,20.9,120.8,50.1l-28.9,28.9
	c-27.1-23.9-62.6-38.5-101.5-38.5c-84.7,0-153.6,68.9-153.6,153.6S156,424.4,240.6,424.4c84.7,0,153.6-68.9,153.6-153.6
	c0-38.9-14.5-74.4-38.5-101.5l28.9-28.9C413.9,172.8,432.5,214.9,434.7,261.3z M443.3,30.9l2.4,26.3c0.4,4.6,4.1,8.3,8.7,8.7
	l26.3,2.4l-34,34L413,98.5l-3.7-33.6L443.3,30.9z M449.1,121.8c0.4,0,0.7,0.1,1.1,0.1c2.5,0,5-1,6.8-2.8l51.7-51.7
	c2.6-2.6,3.5-6.6,2.2-10.1c-1.3-3.5-4.4-6-8.2-6.3l-38.7-3.5l-3.5-38.7c-0.3-3.7-2.8-6.9-6.3-8.1c-3.5-1.3-7.4-0.4-10.1,2.2
	l-51.7,51.7c-2.1,2.1-3.1,5-2.7,7.8l4.6,41.2l-9.7,9.7c-35.8-32.7-82.7-53.5-134.4-55.8V39.3c0-5.3-4.3-9.6-9.6-9.6
	c-5.3,0-9.6,4.3-9.6,9.6v18.2C120.9,62.5,32.2,151.1,27.3,261.3H9.1c-5.3,0-9.6,4.3-9.6,9.6c0,5.3,4.3,9.6,9.6,9.6h18.2
	c4.9,110.1,93.6,198.8,203.7,203.7v18.2c0,5.3,4.3,9.6,9.6,9.6c5.3,0,9.6-4.3,9.6-9.6v-18.2c110.1-4.9,198.8-93.6,203.7-203.7h18.2
	c5.3,0,9.6-4.3,9.6-9.6c0-5.3-4.3-9.6-9.6-9.6h-18.2c-2.3-51.7-23-98.6-55.8-134.4l9.7-9.7L449.1,121.8z"/>
                                                </svg>
                  </div><!-- .service-icon -->
                  <div class="service-text">
                    <h4 class="title">Transportation Notification</h4>
                    <p>Informing commuters and residents about the availability of buses. </p>
                  </div><!-- .service-text -->
                </div><!-- .service -->
              </div><!-- .card-inner -->
            </div><!-- .card -->
          </div><!-- .col -->
          <div class="col-md-12 col-lg-4">
            <div class="card card-shadow">
              <div class="card-inner card-inner-lg">
                <div class="service">
                  <div class="service-icon styled-icon styled-icon-6x text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" style="fill: currentColor;">
                      <rect x="5" y="10" width="70" height="60" rx="7" ry="7" fill="#e3e7fe" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></rect>
                      <rect x="15" y="20" width="70" height="60" rx="7" ry="7" fill="#fff" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></rect>
                      <path
                          d="M47.4,52.58S37.23,62.76,31.63,56.16c-4.88-5.76-5.13-11.09-1.41-14.81s11.53-3.87,15.92,1.19,10,10.79,12.49,12.35,11.83,2.75,13.62-5.36-5.13-9.3-7.59-9.67c-.69-.1-2.27,1-4.39,2.29C54.93,45.42,47.4,52.58,47.4,52.58Z"
                          fill="#e3e7fe" fill-rule="evenodd"></path>
                      <path
                          d="M44.66,41.42a11,11,0,0,0-4.81-2.78,10.12,10.12,0,1,0,0,19.72,11,11,0,0,0,4.81-2.78q1.58-1.45,3.1-2.94l-.2-.19c-1,1-2.05,2-3.08,2.93a10.65,10.65,0,0,1-4.7,2.71,9.84,9.84,0,1,1,0-19.18,10.65,10.65,0,0,1,4.7,2.71c4.52,4.22,8.85,8.64,13.38,12.86A9.48,9.48,0,0,0,62,56.89a8.61,8.61,0,1,0,0-16.78,9.48,9.48,0,0,0-4.11,2.41c-1,.95-2,1.91-3,2.88l.2.19,3-2.87a9.3,9.3,0,0,1,4-2.34,8.34,8.34,0,1,1,0,16.24,9.3,9.3,0,0,1-4-2.34c-4.52-4.22-8.85-8.65-13.38-12.86Z"
                          fill="#6576ff" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" fill-rule="evenodd"></path>
                    </svg>
                  </div><!-- .service-icon -->
                  <div class="service-text">
                    <h4 class="title">Towns Updates</h4>
                    <p>Providing towns updates and news to their residents. </p>
                  </div><!-- .service-text -->
                </div><!-- .service -->
              </div><!-- .card-inner -->
            </div><!-- .card -->
          </div><!-- .col -->
          <div class="col-md-12 col-lg-4">
            <div class="card card-shadow">
              <div class="card-inner card-inner-lg">
                <div class="service">
                  <div class="service-icon styled-icon styled-icon-6x text-primary">
                    <svg viewBox="0 0 512 512" style="fill:currentColor" xml:space="preserve">
                                                    <path
                                                        d="M483.1,57.8h-128V28.9c0-16-12.9-28.9-28.9-28.9H185.8c-16,0-28.9,12.9-28.9,28.9v28.9h-128C12.9,57.8,0,70.8,0,86.7v313.8		c0,16,12.9,28.9,28.9,28.9h158.3l-11.8,41.3h-35.1c-4.6,0-8.3,3.7-8.3,8.3v24.8c0,4.6,3.7,8.3,8.3,8.3h231.2c4.6,0,8.3-3.7,8.3-8.3		V479c0-4.6-3.7-8.3-8.3-8.3h-35.1l-11.8-41.3h158.3c16,0,28.9-12.9,28.9-28.9V86.7C512,70.8,499.1,57.8,483.1,57.8z M277.6,16.5		l-2.8,8.3h-37.6l-2.8-8.3H277.6z M173.4,28.9c0-6.8,5.5-12.4,12.4-12.4H217l6.4,19.1c1.1,3.4,4.3,5.6,7.8,5.6h49.5		c3.6,0,6.7-2.3,7.8-5.6l6.4-19.1h31.2c6.8,0,12.4,5.5,12.4,12.4v239.5c0,6.8-5.5,12.4-12.4,12.4H185.8c-6.8,0-12.4-5.5-12.4-12.4		V28.9z M363.4,487.2v8.3H148.6v-8.3H363.4z M192.6,470.7l11.8-41.3h103.2l11.8,41.3H192.6z M495.5,400.5c0,6.8-5.5,12.4-12.4,12.4		H28.9c-6.8,0-12.4-5.5-12.4-12.4v-20.6h479V400.5z M495.5,363.4h-479V86.7c0-6.8,5.5-12.4,12.4-12.4h128v194.1		c0,16,12.9,28.9,28.9,28.9H223v32c0,4.6,3.7,8.3,8.3,8.3c4.6,0,8.3-3.7,8.3-8.3v-32h8.3v16.5c0,4.6,3.7,8.3,8.3,8.3		c4.6,0,8.3-3.7,8.3-8.3v-16.5h8.3v41.3c0,4.6,3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3v-41.3h37.2c16,0,28.9-12.9,28.9-28.9V74.3h128		c6.8,0,12.4,5.5,12.4,12.4V363.4z"></path>
                                                </svg>
                  </div><!-- .service-icon -->
                  <div class="service-text">
                    <h4 class="title">Applications Development</h4>
                    <p>We develop modern and user friendly Web and Mobile applications. </p>
                  </div><!-- .service-text -->
                </div><!-- .service -->
              </div><!-- .card-inner -->
            </div><!-- .card -->
          </div><!-- .col -->
          <div class="col-md-12 col-lg-4">
            <div class="card card-shadow">
              <div class="card-inner card-inner-lg">
                <div class="service">
                  <div class="service-icon styled-icon styled-icon-6x text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" style="fill:currentColor">
                      <rect x="44" y="24" width="41" height="43" transform="translate(129 91) rotate(-180)"
                            fill="#fff" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      <path
                          d="M71,37V33.6A1.6,1.6,0,0,0,69.4,32H54.6A1.6,1.6,0,0,0,53,33.6V48.39A1.61,1.61,0,0,0,54.61,50H69.4A1.6,1.6,0,0,0,71,48.4V37Z"
                          fill="#eff1ff"/>
                      <line x1="60" y1="42" x2="60" y2="45" fill="none" stroke="currentColor" stroke-width="1.5"/>
                      <line x1="58" y1="46" x2="56" y2="46" fill="none" stroke="currentColor" stroke-width="1.5"/>
                      <rect x="64" y="35" width="4" height="4" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"/>
                      <rect x="56" y="35" width="4" height="4" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"/>
                      <rect x="64" y="42" width="4" height="4" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5"/>
                      <polyline points="58 42 56 42 56 44" fill="none" stroke="currentColor" stroke-width="1.5"/>
                      <path d="M48,53H78a0,0,0,0,1,0,0v9a0,0,0,0,1,0,0H48a2,2,0,0,1-2-2V55a2,2,0,0,1,2-2Z"
                            transform="translate(124 115) rotate(-180)" fill="#e3e7fe"/>
                      <circle cx="70" cy="28" r="0.5" fill="none" stroke="currentColor" stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"/>
                      <circle cx="75" cy="28" r="0.5" fill="none" stroke="#f4bd0e" stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"/>
                      <circle cx="80" cy="28" r="0.5" fill="none" stroke="#eb6459" stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"/>
                      <rect x="5" y="9" width="40" height="72" rx="3" ry="3" fill="#e3e7fe" stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"/>
                      <rect x="9" y="22" width="32" height="43" fill="#fff"/>
                      <polyline points="31 34 35 34 35 38" fill="none" stroke="currentColor" stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"/>
                      <polyline points="19 54 15 54 15 50" fill="none" stroke="currentColor" stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"/>
                      <polyline points="15 38 15 34 19 34" fill="none" stroke="currentColor" stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"/>
                      <polyline points="35 50 35 54 31 54" fill="none" stroke="currentColor" stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"/>
                      <line x1="25" y1="41" x2="25" y2="47" fill="none" stroke="currentColor" stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"/>
                      <line x1="22" y1="44" x2="28" y2="44" fill="none" stroke="currentColor" stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"/>
                      <circle cx="25" cy="74" r="3" fill="none" stroke="currentColor" stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"/>
                      <line x1="23" y1="15" x2="28" y2="15" fill="none" stroke="#c4cefe" stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"/>
                    </svg>
                  </div><!-- .service-icon -->
                  <div class="service-text">
                    <h4 class="title">Smart Meter Reader</h4>
                    <p>Scanning Water Meters and get your amount of units your used that month. </p>
                  </div><!-- .service-text -->
                </div><!-- .service -->
              </div><!-- .card-inner -->
            </div><!-- .card -->
          </div><!-- .col -->
        </div><!-- .row -->
      </div><!-- .section-content -->
    </div><!-- .container -->
  </section><!-- .section -->
</template>

<script>
export default {
  name: "services"
}
</script>

<style scoped>

</style>