import { createRouter, createWebHistory } from 'vue-router'
import Landing from '@/views/Landing.vue'
import Privacy from '@/components/privacy.vue'

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/privacy',
    name : 'Privacy',
    component: Privacy
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
