<template>
  <section class="section section-product" id="product">
    <div class="container">
      <div class="row justify-content-center text-tenter">
        <div class="col-lg-7">
          <div class="section-head text-center">
            <h3 class="title">Developed for the people </h3>
            <p>A variety of products that aims to improve Namibian cities operations and technology by
              connecting with citizens and corporates</p>
          </div><!-- .section-head -->
        </div><!-- .col -->
      </div><!-- .row -->
      <div class="section-content">
        <div class="row gx-gs gy-5">
          <div class="col-md-6">
            <a href="https://barterit.app/" class="card product product-s1">
              <div class="card-inner product-img bg-dark">
                <img src="@/assets/images/product/product1.png" height="325" alt="">
              </div>
              <div class="product-info">
                <div class="product-desc">
                  <h5 class="title">Barter</h5>
                  <span class="sub-title">A buying and selling Mobile Application</span>
                </div>
                <div class="product-price">
                  <del></del>
                  <span></span></div>
              </div>
            </a><!-- .product -->
          </div><!-- .col -->
          <div class="col-md-6">
            <a href="https://munibill.alphanumeric.com.na/" class="card product product-s1">
              <div class="card-inner product-img bg-dark">
                <img src="@/assets/images/product/product3.png" height="325" alt="">
              </div>
              <div class="product-info">
                <div class="product-desc">
                  <h5 class="title">Muni Bill</h5>
                  <span class="sub-title">Municipality Bills Sending System</span>
                </div>
                <div class="product-price">
                  <del></del>
                  <span></span></div>
              </div>
            </a><!-- .product -->
          </div><!-- .col -->
          <div class="col-md-6">
            <a href="/demo3/index.html" class="card product product-s1">
              <div class="card-inner product-img bg-dark">
                <img src="@/assets/images/product/coming-soon.jpg" height="325" alt="">
              </div>
              <div class="product-info">
                <div class="product-desc">
                  <h5 class="title">Tynlink</h5>
                  <span class="sub-title">Long to Short Url API</span>
                </div>
                <div class="product-price">
                  <del></del>
                  <span></span></div>
              </div>
            </a><!-- .product -->
          </div><!-- .col -->
          <div class="col-md-6">
            <a href="/demo4/index.html" class="card product product-s1">
              <div class="card-inner product-img bg-dark">
                <img src="@/assets/images/product/under.png" height="325" alt="">
              </div>
              <div class="product-info">
                <div class="product-desc">
                  <h5 class="title">hyke</h5>
                  <span class="sub-title">A transport Mobile Application</span>
                </div>
                <div class="product-price">
                  <del></del>
                  <span></span></div>
              </div>
            </a><!-- .product -->
          </div><!-- .col -->
        </div><!-- .row -->
      </div><!-- .section-content -->
    </div><!-- .container -->
  </section><!-- .section product -->
</template>

<script>
export default {
  name: "products"
}
</script>

<style scoped>

</style>