<template>
  <section class="section section-cta text-light py-0" id="cta">
    <div class="container">
      <!--        <div class="card bg-dark bg-grad-c">-->
      <!--          <div class="card-inner p-5">-->
      <!--            <div class="row justify-content-between align-items-center g-gs">-->
      <!--              <div class="col-lg-8">-->
      <!--                <div class="text-block">-->
      <!--                  <h3 class="title text-light">Looking For More Services? Let Us Know!</h3>-->
      <!--                  <p>Suggest any Smart Product, services, API & Dataset that you think will positively benefit your-->
      <!--                    community.</p>-->
      <!--                </div>&lt;!&ndash; .text-block &ndash;&gt;-->
      <!--              </div>&lt;!&ndash; .col &ndash;&gt;-->
      <!--              <div class="col-lg-4 d-flex justify-content-lg-end">-->
      <!--                <a href="#faqs" class="btn btn-danger btn-round btn-lg">Suggest Today</a>-->
      <!--              </div>-->
      <!--            </div>&lt;!&ndash; .row &ndash;&gt;-->
      <!--          </div>&lt;!&ndash; .card-inner &ndash;&gt;-->
      <!--        </div>&lt;!&ndash; .card &ndash;&gt;-->
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <div class="row align-items-center g-0">
            <div class="col-md-7">
              <div class="card  card-shadow round-xl bg-indigo is-dark pb-4 pb-md-0">
<!--                <img src="@/assets/images/shape/shape-a.png"-->
<!--                     alt="" width="30" height="300" class="card-img">-->
                <div class="card-inner card-inner-xl">
                  <div class="text-block"><h3 class="title ">You can also suggest</h3>
                    <ul class="list fs-16px">
                      <li>Smart Products</li>
                      <li>Smart Services</li>
                      <li>Smart APIs</li>
                      <li>Datasets</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="card card-shadow round-xl ml-lg-n7 ml-md-n5 mx-4 mr-md-0 mt-md-0 mt-n4">
                <div class="card-inner card-inner-lg">
                  <div class="form-block">
                    <div class="section-head section-head-sm"><h4 class="title">Looking For More Services? Let Us
                      Know!</h4>
                    </div>
                    <form v-if="success == null"  class="form-submit" id="formSubmit">
                      <div class="row g-4">
                        <div class="col-12">
                          <div class="form-group "><label class="form-label" for="name">Your Name</label>
                            <div class="form-control-wrap"><input type="text" v-model="name"
                                                                  class="form-control form-control-lg"
                                                                  id="name" placeholder="Your Full Name"></div>
                          </div>
                        </div>
<!--                        <div class="col-12">-->
<!--                          <div class="form-group "><label class="form-label" for="subject">Your Town</label>-->
<!--                            <div class="form-control-wrap"><input type="text" v-model="town"-->
<!--                                                                  class="form-control form-control-lg"-->
<!--                                                                  id="subject" placeholder="Your Town"></div>-->
<!--                          </div>-->
<!--                        </div>-->
                        <div class="col-12">
                          <div class="form-group"><label class="form-label" for="email">Your Email</label>
                            <div class="form-control-wrap"><input type="text" v-model="email"
                                                                  class="form-control form-control-lg"
                                                                  id="email" placeholder="name@email.com"></div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group"><label class="form-label" for="question">Message</label>
                            <div class="form-control-wrap"><textarea v-model="message" class="form-control  no-resize"
                                                                     id="question"
                                                                     placeholder="Your Message"></textarea></div>
                          </div>
                        </div>
                        <div class="col-12"><button data-value="submit" @click.prevent="send" type="submit"
                                               class="btn btn-lg btn-primary">Submit</button></div>
                      </div>
                    </form>
                    <div v-else class="alert alert-pro alert-primary">
                      <div class="alert-text">
                        <h6>Thank you for your Suggestion</h6>
                        <p>We will contact you regarding this. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- .container -->
  </section><!-- .section -->
</template>

<script>
import Mailgun from 'mailgun.js';

let mailgun = new Mailgun(FormData);
let mg = mailgun.client({
  username: 'api',
  key: process.env.MAILGUN_API_KEY || 'key-40b23a0a7f7a9edf90c57f2d70784cdd'
})

export default {
  name: "suggestion",
  data() {
    return {
      name: null,
      email: null,
      message: null,
      town: null,
      success:null,
      error:null,
    }
  },
  methods: {
    send() {
      let mail = {
        from: this.email,
        to: ['erro@alphanumeric.com.na','naftal@alphanumeric.com.na','hello@alphanumeric.com.na','mgabriel@alphanumeric.com.na'],
        cc:['pandenierro@gmail.com', 'tnaffh@gmail.com', 'gmossesfly@gmail.com'],
        subject: 'Smart City Contact Form',
        text: this.message,
      };
      mg.messages.create('mg.alphanumeric.com.na',
        mail
      )
          .then((res)=>{
            this.success = res
            console.log('message sent')
            console.log(res)
          })
          .catch(error => console.log(error));


      // mg.messages().send(mail,(error, body)=>{
      //   console.log(body);
      //   console.log(error);
      // });
    }
  }
}
</script>

<style scoped>

</style>