<template>
  <footer class="footer bg-dark is-dark">
    <div class="container">
      <div class="row justify-content-between py-4 py-md-6">
        <div class="col-lg-4 col-md-6">
          <div class="widget widget-about">
            <a href="html/index.html" class="logo-link">
              <img class="logo-light logo-img" src="@/assets/img/logo-white.png" alt="logo">
            </a>

            <p>"You can’t connect the dots looking forward, you can only connect them looking backwards. So you have
              to trust that the dots will somehow connect in your future.” ― Steve Jobs</p>
          </div>
        </div><!-- .col -->
        <div class="col-lg-6 col-12">
          <div class="widget">
            <h6 class="widget-title">Resources</h6>
            <ul class="widget-link link-inline link-inline-2col link-inline-md-3col g-2 py-2">
              <li><a href="#">Products</a></li>
              <li><a href="#">Support Center</a></li>
              <li><a href="#">Frequent Questions</a></li>
              <li><a href="#">Terms of Service</a></li>
              <li><router-link to="/privacy">Privacy Policy</router-link></li>
              <li><a href="#">Email Services</a></li>
              <li><a href="#">Bulk SMS Services</a></li>
              <li><a href="#">Contact Us</a></li>
            </ul>
          </div>
        </div><!-- .col -->
      </div><!-- .row -->
      <hr class="hr border-light mb-0 mt-n1">
      <div class="row g-3 align-items-center justify-content-md-between py-4">
        <div class="col-md-8">
          <div> &copy; 2021 <a class="text-base fw-bold" href="#">Alphanumeric Technology CC</a>
          </div>
        </div><!-- .col -->
        <div class="col-md-4 d-flex justify-content-md-end">
          <ul class="social">
            <li><a href="#"><em class="icon ni ni-twitter"></em></a></li>
            <li><a href="#"><em class="icon ni ni-facebook-f"></em></a></li>
            <li><a href="#"><em class="icon ni ni-instagram"></em></a></li>
            <li><a href="#"><em class="icon ni ni-pinterest"></em></a></li>
          </ul><!-- .footer-icon -->
        </div><!-- .col -->
      </div><!-- .row -->
    </div><!-- .container -->
    <router-view></router-view>
  </footer>
</template>

<script>
//import Privacy from "../components/privacy";

export default {
  name: "footer",
  //components: {Privacy}
}
</script>

<style scoped>

</style>