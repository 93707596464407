<template>
  <section class="section section-plugin bg-lighter text-center" id="plugin">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="section-head text-center">
            <h3 class="title">City infrastructures </h3>
            <p>You can report cases of any damaged infrastructures in your respective town, and your town's agents
              will attend them. </p>
          </div><!-- .section-head -->
        </div><!-- .col -->
      </div><!-- .row -->
      <div class="row justify-content-center">
        <div class="col-xl-8 col-lg-10">
          <div class="row g-gs">
            <div class="col-md-3 col-sm-4 col-6">
              <div class="card card-full">
                <div class="card-inner">
                  <div class="card-img mb-3">
                    <img class="h-60px" src="@/assets/img/infrastructures/fire-hydrant.png"
                         alt="leaking fire hydrants">
                  </div>
                  <div class="text-dark h6">leaking fire hydrants</div>
                </div>
              </div><!-- .card -->
            </div><!-- .col -->
            <div class="col-md-3 col-sm-4 col-6">
              <div class="card card-full">
                <div class="card-inner">
                  <div class="card-img mb-3">
                    <img class="h-60px" src="@/assets/img/infrastructures/waterLeakage.png" alt="">
                  </div>
                  <div class="text-dark h6">burst pipes</div>
                </div>
              </div><!-- .card -->
            </div><!-- .col -->
            <div class="col-md-3 col-sm-4 col-6">
              <div class="card card-full">
                <div class="card-inner">
                  <div class="card-img mb-3">
                    <img class="h-60px" src="@/assets/img/infrastructures/DemagedEletricity.png" alt="">
                  </div>
                  <div class="text-dark h6">Damaged electricity</div>
                </div>
              </div><!-- .card -->
            </div><!-- .col -->
            <div class="col-md-3 col-sm-4 col-6">
              <div class="card card-full">
                <div class="card-inner">
                  <div class="card-img mb-3">
                    <img class="h-60px" src="@/assets/img/infrastructures/toilet.png" alt="">
                  </div>
                  <div class="text-dark h6">Damaged Toilets</div>
                </div>
              </div><!-- .card -->
            </div><!-- .col -->
            <div class="col-md-3 col-sm-4 col-6">
              <div class="card card-full">
                <div class="card-inner">
                  <div class="card-img mb-3">
                    <img class="h-60px" src="@/assets/img/infrastructures/traffic-light.png" alt="">
                  </div>
                  <div class="text-dark h6">Traffic lights</div>
                </div>
              </div><!-- .card -->
            </div><!-- .col -->
            <div class="col-md-3 col-sm-4 col-6">
              <div class="card card-full">
                <div class="card-inner">
                  <div class="card-img mb-3">
                    <img class="h-60px" src="@/assets/img/infrastructures/splash.png" alt="">
                  </div>
                  <div class="text-dark h6">Damaged Sewage</div>
                </div>
              </div><!-- .card -->
            </div><!-- .col -->
            <div class="col-md-3 col-sm-4 col-6">
              <div class="card card-full">
                <div class="card-inner">
                  <div class="card-img mb-3">
                    <img class="h-60px" src="@/assets/img/infrastructures/bins.png" alt="">
                  </div>
                  <div class="text-dark h6">Community Bins</div>
                </div>
              </div><!-- .card -->
            </div><!-- .col -->
            <div class="col-md-3 col-sm-4 col-6">
              <div class="card card-full">
                <div class="card-inner">
                  <div class="card-img mb-3">
                    <img class="h-60px" src="@/assets/img/infrastructures/sign.png" alt="">
                  </div>
                  <div class="text-dark h6">Roads and street signs</div>
                </div>
              </div><!-- .card -->
            </div><!-- .col -->
          </div><!-- .row -->
        </div>
      </div>
    </div><!-- .container -->
  </section><!-- .section-plugin -->
</template>

<script>
export default {
  name: "infrastructures"
}
</script>

<style scoped>

</style>