
window.$ = window.jQuery = require('jquery');

require('popper.js')
require('bootstrap')

require('simplebar')
require('select2')
require('sweetalert2')
require('toastr')

require('jquery-validation')
require('slick-carousel')

require('nouislider')
require('dropzone')

require('jquery-timepicker/jquery.timepicker')
require('bootstrap-datepicker')



require('magnific-popup')

require('./scripts')