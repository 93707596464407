<template>
  <section class="section section-cta bg-dark is-dark has-bg-image" id="download">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-8 col-md-10">
          <div class="section-head"><h2 class="title fw-medium">Download the App Now</h2>
            <p></p></div>
        </div>
      </div>
      <div class="row justify-content-center text-center">
        <div class=" ">
          <a href="https://play.google.com/store/apps/details?id=app.alphanumeric.smart_city" class="mr-3 mb-2 ">
            <img src="@/assets/img/play&appStore/google-play.png" height="50" alt="Google Play">
          </a>
          <a href="#"  class="mb-2">
            <img src="@/assets/img/play&appStore/App_Store.png" height="50" alt="Apple Store">
          </a>
        </div>
      </div>
    </div>
    <div class="bg-image bg-overlay after-bg-dark after-opacity-90 overlay-fall bg-image-loaded"
         style="background-image: url('./src/assets/images/bg/b.jpg');">
      <img src="@/assets/images/bg/b.jpg" alt="">
    </div>
  </section>
</template>

<script>
export default {
  name: "download"
}
</script>

<style scoped>

</style>