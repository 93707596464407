<template>
  <section class="section section-sm bg-white">
    <div class="container">
      <div class="section-head justify-content-center gx-5 gy-4 text-center">
        <h2 class="title">Trusted by</h2>
        <p class="fs-15px">We have the pleasure to work with the bellow clients and your town can be next.</p>
      </div>
      <div class="row justify-content-center gx-5 gy-4 text-center">
        <div class="col-4 col-lg-1 ">
          <div class="brand-item h-40px">
            <img src="@/assets/img/clients/arandis.jpeg" alt="Arandis TC">
          </div>
        </div><!-- .col -->
        <div class="col-4 col-lg-1 ">
          <div class="brand-item h-40px">
            <img src="@/assets/img/clients/keetmashoop.png" alt="">
          </div>
        </div><!-- .col -->
        <div class="col-4 col-lg-1 ">
          <div class="brand-item h-40px">
            <img src="@/assets/img/clients/outapi.jpeg" alt="">
          </div>
        </div><!-- .col -->
        <div class="col-4 col-lg-1 ">
          <div class="brand-item h-40px">
            <img src="@/assets/img/clients/oshtc.png" alt="">
          </div>
        </div><!-- .col -->
        <div class="col-4 col-lg-1 ">
          <div class="brand-item h-40px">
            <img src="@/assets/img/clients/bethanie.png" alt="">
          </div>
        </div><!-- .col -->
        <div class="col-4 col-lg-1 ">
          <div class="brand-item h-40px">
            <img src="@/assets/img/clients/eenhana.jpg" alt="">
          </div>
        </div><!-- .col -->
        <div class="col-4 col-lg-1 ">
          <div class="brand-item h-40px">
            <img src="@/assets/img/clients/oniipa.jpeg" alt="">
          </div>
        </div>
        <div class="col-4 col-lg-1 ">
          <div class="brand-item h-40px">
            <img src="@/assets/img/clients/HelaoOtc.jpg" alt="">
          </div>
        </div>
        <div class="col-4 col-lg-1">
          <div class="brand-item h-40px">
            <img src="@/assets/img/clients/opuwo_logo.jpg" alt="">
          </div>
        </div><!-- .col -->
      </div><!-- .row -->
    </div><!-- .header-brand -->
  </section>
</template>

<script>
export default {
  name: "clients"
}
</script>

<style scoped>

</style>