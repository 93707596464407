<template>
  <nav class="header-menu" data-content="mainNav">
    <ul class="menu-list ml-lg-auto">
      <!--      <li class="menu-item has-sub">
              <a href="#" class="menu-link menu-toggle">Landing</a>
              <div class="menu-sub">
                <ul class="menu-list">
                  <li class="menu-item"><a href="html/index.html" class="menu-link">Landing Page - v1</a></li>
                  <li class="menu-item"><a href="html/index-v2.html" class="menu-link">Landing Page - v2</a>
                  </li>
                  <li class="menu-item"><a href="html/index-v3.html" class="menu-link">Landing Page - v3</a>
                  </li>
                  <li class="menu-item"><a href="html/index-v4.html" class="menu-link">Landing Page - v4</a>
                  </li>
                  <li class="menu-item"><a href="html/index-v5.html" class="menu-link">Landing Page - v5</a>
                  </li>
                  <li class="menu-item"><a href="html/index-v6.html" class="menu-link">Landing Page - v6</a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="menu-item has-sub">
              <a href="#" class="menu-link menu-toggle">Pages</a>
              <div class="menu-sub">
                <ul class="menu-list">
                  <li class="menu-item has-sub">
                    <a href="#" class="menu-link menu-toggle">Auth Pages</a>
                    <div class="menu-sub">
                      <ul class="menu-list">
                        <li class="menu-item"><a href="html/pages/auths/auth-login.html" class="menu-link"
                                                 target="_blank">Login / Signin</a></li>
                        <li class="menu-item"><a href="html/pages/auths/auth-login-v2.html" class="menu-link"
                                                 target="_blank">Login / Signin v2</a></li>
                        <li class="menu-item"><a href="html/pages/auths/auth-login-v3.html" class="menu-link"
                                                 target="_blank">Login / Signin v3</a></li>
                        <li class="menu-item"><a href="html/pages/auths/auth-register.html" class="menu-link"
                                                 target="_blank">Register / Signup</a></li>
                        <li class="menu-item"><a href="html/pages/auths/auth-register-v2.html" class="menu-link"
                                                 target="_blank">Register / Signup v2</a></li>
                        <li class="menu-item"><a href="html/pages/auths/auth-register-v3.html" class="menu-link"
                                                 target="_blank">Register / Signup v3</a></li>
                        <li class="menu-item"><a href="html/pages/auths/auth-reset.html" class="menu-link"
                                                 target="_blank">Forgot Password</a></li>
                        <li class="menu-item"><a href="html/pages/auths/auth-reset-v2.html" class="menu-link"
                                                 target="_blank">Forgot Password v2</a></li>
                        <li class="menu-item"><a href="html/pages/auths/auth-reset-v3.html" class="menu-link"
                                                 target="_blank">Forgot Password v3</a></li>
                      </ul>
                    </div>
                  </li>
                  <li class="menu-item has-sub">
                    <a href="#" class="menu-link menu-toggle">Error Pages</a>
                    <div class="menu-sub">
                      <ul class="menu-list">
                        <li class="menu-item"><a href="html/pages/errors/404-classic.html" target="_blank"
                                                 class="menu-link">404 Classic</a></li>
                        <li class="menu-item"><a href="html/pages/errors/404-modern.html" target="_blank"
                                                 class="menu-link">404 Modern</a></li>
                        <li class="menu-item"><a href="html/pages/errors/504-classic.html" target="_blank"
                                                 class="menu-link">504 Classic</a></li>
                        <li class="menu-item"><a href="html/pages/errors/504-modern.html" target="_blank"
                                                 class="menu-link">504 Modern</a></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>-->
      <li class="menu-item"><a href="#home" class="menu-link nav-link">Home</a></li>
      <li class="menu-item"><a href="#service" class="menu-link nav-link">Services</a></li>
      <li class="menu-item"><a href="#product" class="menu-link nav-link">Products</a></li>
      <li class="menu-item"><a href="#plugin" class="menu-link nav-link">Solutions</a></li>
      <li class="menu-item"><a href="#cta" class="menu-link nav-link">Get In Touch</a></li>
    </ul>
    <ul class="menu-btns navbar">
      <li>
          <a class="btn btn-primary btn-sm" href="#download">
            Get the App</a>
      </li>
    </ul>
  </nav><!-- .nk-nav-menu -->
</template>

<script>
export default {
  name: "NavHeader"
}
</script>

<style scoped>

</style>