<template>
  <div class="header-content my-auto py-5 is-dark">
    <div class="container">
      <div class="row flex-row-reverse  justify-content-center g-gs">
        <div class="col-lg-6 ">
          <div class="header-image">
            <img src="@/assets/images/bg/bg2.png" alt="">
          </div><!-- .header-image -->
        </div><!-- .col- -->
        <div class="col-lg-6">
          <div class="header-caption">
            <div class="header-badge">
              <div class="badge-pro">
                <span class="badge badge-danger badge-pill">NEW</span> <span class="badge-pro-text">Now available on App Store & Play Store</span>
              </div>
            </div>
            <h1 class="header-title">A Gateway to City services and information</h1>
            <div class="header-text pr-5">
              <p>SmartCity is the first and only Namibian application that provides you access to Cities services
                and digital information from over 40 local authorities.
                Our aim is to offer you a one-stop experience for all your City/Town interactions, available
                seamlessly and safely at your finger-tips. We are always adding more services to ease your life.</p>
            </div>
            <ul class="header-action btns-inline">
              <li>
                <a href="https://app.smartcity.com.na/" class="btn btn-danger btn-round btn-lg"><span>Let's Start</span></a>
              </li>
            </ul><!-- .header-action -->
          </div><!-- .header-caption -->
        </div><!-- .col -->
      </div><!-- .row -->
    </div><!-- .container -->
  </div><!-- .header-content -->
</template>

<script>

export default {
  name: "HomeContent"
}
</script>

<style scoped>

</style>