<template>
  <section class="section section-facts bg-dark" id="facts">
    <div class="container">
      <div class="row g-gs flex-sm-row-reverse justify-content-center align-items-center">
        <div class="col-md-6 col-sm-3 text-sm-center is-dark">
          <a class="play popup-video" href="https://www.youtube.com/watch?v=M7FIvfx5J10">
            <div class="styled-icon styled-icon-6x styled-icon-s5 text-white">
              <svg x="0px" y="0px" viewBox="0 0 512 512" style="fill:currentColor" xml:space="preserve">
                                        <path d="M436.2,178.3c-7.5-4.7-17.4-2.4-22.1,5.1c-4.7,7.5-2.4,17.4,5.1,22.1c17.5,10.9,28,29.8,28,50.4s-10.5,39.5-28,50.4
	L155.7,470.7c-18.6,11.6-41.1,12.2-60.3,1.5c-19.2-10.6-30.6-30.1-30.6-52V91.7c0-21.9,11.4-41.3,30.6-52
	c19.1-10.6,41.7-10.1,60.3,1.5l153.4,95.6c7.5,4.7,17.4,2.4,22.1-5.1c4.7-7.5,2.4-17.4-5.1-22.1L172.7,14
	c-28.6-17.9-63.3-18.7-92.9-2.4C50.3,28.1,32.7,58,32.7,91.7v328.6c0,33.7,17.6,63.7,47.1,80c14.1,7.8,29.3,11.7,44.5,11.7
	c16.7,0,33.4-4.7,48.4-14l263.5-164.3c27-16.8,43.1-45.9,43.1-77.7S463.2,195.2,436.2,178.3z"/>
                                    </svg>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-sm-9">
          <div class="text-block is-dark pr-xl-5">
            <h6 class="title-xs fs-15px text-purple"></h6>
            <h3 class="title"> you should make us your premier choice </h3>
          </div>
          <div class="row g-gs">
            <div class="col-sm-4 col-6">
              <div class="facts facts-s2">
                <div class="facts-card">
                  <div class="h2 fw-bold text-danger pb-1">97 563+</div>
                  <div class="h6 fw-normal text-white">Happy Clients</div>
                </div>
              </div><!-- .facts -->
            </div><!-- .col -->
            <div class="col-sm-4 col-6">
              <div class="facts facts-s2">
                <div class="facts-card">
                  <div class="h2 fw-bold text-success pb-1">10</div>
                  <div class="h6 fw-normal text-white">Smart Cities</div>
                </div>
              </div><!-- .facts -->
            </div><!-- .col -->
            <div class="col-sm-4 col-6">
              <div class="facts facts-s2">
                <div class="facts-card">
                  <div class="h2 fw-bold text-purple pb-1">643 201+</div>
                  <div class="h6 fw-normal text-white">Bills Delivered</div>
                </div>
              </div><!-- .facts -->
            </div><!-- .col -->
          </div><!-- .row -->
        </div><!-- .col -->
      </div><!-- .row -->
    </div><!-- .container -->
    <div class="bg-image bg-contain bg-right bg-overlay bg-overlay-dark-transparent d-none d-md-block">
      <img src="@/assets/images/bg/b.jpg" alt="">
    </div>
  </section><!-- .section -->
</template>

<script>
export default {
  name: "stats"
}
</script>

<style scoped>

</style>